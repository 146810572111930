<template>
  <div id="allLiveTestsCard" class="w-100">
    <v-snackbar color="primary" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-card
      :loading="loading || superLoading"
      elevation="0"
      class="w-100 pb-4 px-1 px-md-4"
    >
      <!-- Title -->
      <h6 class="text-h6 pt-4">
        <v-icon class="mr-1" color="deep-purple"
          >mdi-clipboard-text-multiple</v-icon
        >
        Tests:
      </h6>

      <v-container>
        <!-- Error Row -->
        <v-row align="center" no-gutters v-if="error">
          <v-col cols="12" class="mt-2">
            <v-alert type="error">{{ error }}</v-alert>
          </v-col>
        </v-row>

        <v-divider class="mb-2"></v-divider>

        <!-- Display Tests Row -->
        <v-row justify="center" align="center" v-if="allTests.length > 0">
          <v-container fluid class="pa-0">
            <!-- Tests Cards -->
            <v-row class="my-3" align="center" justify="center" no-gutters>
              <v-col
                cols="12"
                sm="12"
                md="12"
                class="mb-2 d-flex flex-column justify-center align-center"
              >
                <v-list-item
                  v-for="test in allTests"
                  :key="test.id"
                  class="px-0"
                >
                  <v-card
                    id="card-gradient"
                    class="ma-3 pb-1 rounded-lg blue"
                    :max-width="$vuetify.breakpoint.smAndDown ? 260 : '35.7vw'"
                  >
                    <v-list-item>
                      <v-list-item-content>
                        <!-- isBilingual / free or premium -->
                        <div class="text-overline mb-4">
                          <!-- Free Chip -->
                          <v-chip
                            v-if="!test.isPremium"
                            color="green accent-3 white--text"
                            class="mr-2"
                            x-small
                          >
                            Free
                          </v-chip>

                          <v-icon
                            v-else
                            color="amber darken-2"
                            class="mr-2 white rounded-circle pa-1"
                            size="20"
                          >
                            mdi-crown-outline
                          </v-icon>

                          <!-- Language Chip -->
                          <v-chip class="white" x-small>
                            <span v-if="test.isBilingual"> Eng/Hin </span>
                            <span v-else-if="test.onlySecondaryLang">
                              Hindi
                            </span>
                            <span v-else> English </span>
                          </v-chip>
                        </div>

                        <!-- Test name and time -->
                        <v-list-item-title class="text-h5 white--text pl-2">
                          {{ test.testName }}
                        </v-list-item-title>

                        <v-list-item-subtitle class="mt-2 white--text">
                          <!-- Start Time -->
                          <div class="mt-1">
                            <v-icon class="mr-1" small>mdi-clock</v-icon>
                            <b>Start: </b>
                            {{
                              dateTimeText(test.startDateTime.seconds * 1000)
                            }}
                          </div>

                          <!-- End Time -->
                          <div class="my-2">
                            <v-icon class="mr-1" small>mdi-clock-alert</v-icon>
                            <b class="mr-2">End: </b>
                            {{ dateTimeText(test.endDateTime.seconds * 1000) }}
                          </div>

                          <!-- Questions and Duration -->
                          <div>
                            <v-icon class="mr-1" small>mdi-card-text</v-icon>
                            <b>{{ test.questionCount }}</b> Questions

                            <v-icon class="mx-1" small>mdi-timer-sand</v-icon>
                            <b>{{ test.testDuration }}</b>
                            Mins
                          </div>
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <!-- SVG Image (w/ premium effect) -->
                      <v-list-item-avatar
                        v-if="!$vuetify.breakpoint.mobile"
                        tile
                        size="110"
                      >
                        <v-img
                          v-if="test.isPremium"
                          class="mx-auto hidden-sm-and-downs"
                          src="../../../assets/home/dashboard/gold_undraw_Choose_re_7d5a.svg"
                          contain
                        ></v-img>
                        <v-img
                          v-else
                          class="mx-auto hidden-sm-and-downs"
                          src="../../../assets/home/dashboard/undraw_Choose_re_7d5a.svg"
                          contain
                        ></v-img>
                      </v-list-item-avatar>
                    </v-list-item>

                    <!-- Button (Unlock/Take Test) -->
                    <v-card-actions class="pt-0">
                      <v-spacer></v-spacer>
                      <!-- Re-attempt and Result Button -->
                      <template v-if="test.id in results">
                        <v-btn
                          :color="
                            test.isPremium
                              ? 'amber--text text--darken-4 px-3'
                              : 'px-3'
                          "
                          elevation="1"
                          :to="
                            test.isPremium && !isUserSubscribed
                              ? '/premium-pass'
                              : {
                                  name: 'Result',
                                  params: { id: test.id },
                                }
                          "
                          rounded
                          small
                        >
                          <template v-if="test.isPremium">
                            <span v-if="isUserSubscribed">
                              Re-Attempt / Solution
                            </span>
                            <span v-else class="d-flex align-center">
                              <v-icon class="mr-2" size="17">mdi-lock</v-icon
                              >Unlock
                            </span>
                          </template>
                          <template v-else> Result / Solution </template>
                        </v-btn>
                      </template>

                      <!-- Attempt Button -->
                      <v-btn
                        v-else
                        :color="
                          test.isPremium
                            ? 'amber--text text--darken-4 px-3'
                            : 'px-3'
                        "
                        elevation="1"
                        :to="
                          test.isPremium && !isUserSubscribed
                            ? '/premium-pass'
                            : {
                                name: 'Test',
                                params: { id: test.id, testObj: test },
                              }
                        "
                        rounded
                        small
                      >
                        <template v-if="test.isPremium">
                          <span v-if="isUserSubscribed"> Attempt </span>
                          <span v-else class="d-flex align-center">
                            <v-icon class="mr-2" size="17">mdi-lock</v-icon
                            >Unlock
                          </span>
                        </template>
                        <template v-else> Attempt </template>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-list-item>
              </v-col>
            </v-row>

            <!-- Navigation Buttons Row -->
            <v-row class="mt-4" align="center" no-gutters>
              <v-col cols="12" sm="12" class="d-flex justify-center">
                <v-btn
                  class="mx-1"
                  color="primary darken-2"
                  :loading="loading || superLoading"
                  @click="fetchAllHighlightedTests('prev')"
                  text
                >
                  <v-icon>mdi-chevron-left</v-icon> Previous
                </v-btn>
                <v-btn
                  class="mx-1"
                  color="primary darken-2"
                  :loading="loading || superLoading"
                  :disabled="allTests.length < 10"
                  @click="fetchAllHighlightedTests('next')"
                  text
                >
                  Next
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
const dayjs = require("dayjs");

export default {
  name: "AllLiveTestsCard",
  props: ["superLoading", "selectedCourse", "isUserSubscribed"],
  components: {},
  data: () => ({
    loading: true,
    snackbar: false,
    snackbarText: "",
    error: "",
    allTests: [],
    results: {},
    firstAndLastVisible: null,
  }),
  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },

    dateTimeText(timestamp) {
      return dayjs(timestamp).format("hh:mm a, D MMM");
    },

    fetchAllHighlightedTests(requestType) {
      var payload = "";

      switch (requestType) {
        case "prev":
          payload = {
            requestType,
            doc: this.firstAndLastVisible.firstVisible,
            course: this.selectedCourse,
          };
          break;
        case "next":
          payload = {
            requestType,
            doc: this.firstAndLastVisible.lastVisible,
            course: this.selectedCourse,
          };
          break;
        default:
          payload = { requestType: null, course: this.selectedCourse };
      }

      this.setLoading(true);

      this.$store
        .dispatch("getAllHighlightedTests", payload)
        .then((res) => {
          // write data if there is data
          if (res.tests.length > 0) {
            this.snackbarText = "Tests fetched successfully :)";
            this.allTests = res.tests;
            this.results = res.results;
            this.firstAndLastVisible = res.firstAndLastVisible;
          } else {
            //if there is no data show on snackbar accordingly
            switch (requestType) {
              case "prev":
                this.snackbarText = "This is the first page.";
                break;
              case "next":
                this.snackbarText = "This is the last page.";
                break;
              default:
                this.snackbarText = "No tests in added yet :(";
                this.allTests = [];
                this.results = {};
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error;
          this.snackbarText = "Error, please try again :(";
        })
        .finally(() => {
          this.snackbar = true;
          this.setLoading(false);
        });
    },
  },
  mounted() {
    this.fetchAllHighlightedTests();
  },
};
</script>

<style scoped>
#card-gradient {
  background: #fc5c7d; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #6a82fb,
    #fc5c7d
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #879af8,
    #f78fa4
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>